import {FC, useEffect, useState} from "react";
import {Button, ButtonVariant} from "components/common/buttons/Button";
import {Drawer} from "components/common/drawers/Drawer";
import {BagsCounter} from "components/common/orderBuilder/BagsCounter";
import {delay} from "utils/delay";
import styles from "../styles/BagsCountEditDialog.module.scss";

const DICTIONARY = {
  EDIT_NUMBER_OF_BAGS: "Edit number of bags",
  DOORDASH_LIMIT: (amnt: string | number) =>
    `DoorDash has a limit of <strong>${amnt} bags</strong>`,
  HOW_MANY_BAGS: "How many bags?",
  CANCEL: "Cancel",
  APPLY: "Apply",
};

const DEFAULTS = {
  LIMIT: 5,
  MAX: 20,
  MIN: 1,
};

interface IBagsCountEditDialog {
  current: number;
  isVisible: boolean;
  limit?: number;
  onCancel?: () => void;
  onApply?: (count: number) => void;
}

export const BagsCountEditDialog: FC<IBagsCountEditDialog> = ({
  current,
  isVisible,
  limit = DEFAULTS.LIMIT,
  onCancel,
  onApply,
}) => {
  const [count, setCount] = useState<number>(current);
  const [isActive, setIsActive] = useState<boolean>(false);

  const increaseCurrentBagCount = (): void => {
    if (count >= DEFAULTS.MAX) {
      return;
    }

    setCount((count) => count + 1);
  };

  const decreaseCurrentBagCount = (): void => {
    if (count <= DEFAULTS.MIN) {
      return;
    }

    setCount((count) => count - 1);
  };

  useEffect(() => {
    if (isVisible) {
      delay(10).then(() => setIsActive(true));
    }
  }, [isVisible]);

  const handleOnCancel = (): void => {
    setCount(current);
    onCancel?.();
    setIsActive(false);
  };

  const handleOnApply = (): void => {
    onApply?.(count);
    setIsActive(false);
  };

  return (
    <Drawer
      isShowingDrawer={isActive}
      title={DICTIONARY.EDIT_NUMBER_OF_BAGS}
      closeDrawer={handleOnCancel}
    >
      <div className={styles.bagsCountEditDialog}>
        <div>
          <p
            dangerouslySetInnerHTML={{
              __html: DICTIONARY.DOORDASH_LIMIT(limit),
            }}
          />
          <BagsCounter
            onMinus={decreaseCurrentBagCount}
            onPlus={increaseCurrentBagCount}
            value={count}
            max={DEFAULTS.MAX}
            min={DEFAULTS.MIN}
          />
        </div>

        <div className={styles.bagsCountActionButtons}>
          <Button
            onClick={handleOnCancel}
            text={DICTIONARY.CANCEL}
            fullWidth={false}
            variant={ButtonVariant.OUTLINED}
            className={styles.footerButton}
          />
          <Button
            onClick={handleOnApply}
            text={DICTIONARY.APPLY}
            fullWidth={false}
            className={styles.footerButton}
          />
        </div>
      </div>
    </Drawer>
  );
};
