import {Suspense, useEffect} from "react";
import {
  Landing,
  PageNotFound,
  SelfOrder,
  SelfServeWrapper,
  VerifyAccount,
  ReferralValidationPage,
} from "components";
import {Route, Switch, useHistory, useLocation} from "react-router-dom";
import PullToRefresh from "react-simple-pull-to-refresh";
import {NewOrderRoute} from "routes/newOrder";
import {ShortOrderRoute} from "routes/o";
import {OrderRoute} from "routes/order";
import {OrderSummaryRedirect} from "routes/order-summary";
import {SelfServeHome} from "components/SelfServeHome/SelfServeHome";
import {Loader} from "components/common";
import {CentsCustomerProvider} from "components/common/providers/CentsCustomerProvider";
import {PrivateRoute} from "components/common/providers/PrivateRoute";
import {Welcome} from "components/welcome/Welcome";
import {useIsMobile} from "hooks/useIsMobile";
import {ROUTES} from "./constants";

export const RootRoute = () => {
  const location = useLocation();
  const isMobile = useIsMobile();
  const history = useHistory();

  const onPullDownToRefresh = async () => {
    const current = window.location.pathname;
    history.replace(`/reload`);
    setTimeout(() => {
      history.replace(current);
    }, 10);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, [location]);

  const SELF_SERVE_ORDERING_ROUTES = [
    <Route
      key="self-serve-wrapper-path"
      path="/self-serve/:uniqueCode"
      exact
      component={SelfServeWrapper}
    />,
    <PrivateRoute
      key="self-order-path"
      path="/self-order/:id"
      exact
      component={SelfOrder}
    />,
  ];

  return (
    <Suspense fallback={<Loader />}>
      <CentsCustomerProvider>
        <PullToRefresh
          isPullable={isMobile}
          pullDownThreshold={50}
          onRefresh={onPullDownToRefresh}
          pullingContent={<></>}
          refreshingContent={
            <div className="pull-to-refresh">
              <Loader fullScreen={false} variant="compact" />
            </div>
          }
        >
          <Switch>
            <Route path="/reload" component={Loader} key="reload" />
            <Route path={`/${ROUTES.order}`} component={OrderRoute} />

            {/**
             * It is legacy route thaw was used before `/order/:serviceOrderId` route,
             * an encrypted token was used for order identification.
             * Now we use `/order/:serviceOrderId`, so this route
             * gets the service order id from the token (to support old type links)
             * and redirects to the actual order page.
             */}
            <PrivateRoute
              path="/order-summary/:orderToken"
              exact
              component={OrderSummaryRedirect}
            />

            {/** This route is used for short links in SMS and redirects to a long link */}
            <PrivateRoute path="/o/:serviceOrderId" exact component={ShortOrderRoute} />

            <PrivateRoute
              path="/refer/:encodedBusinessLevelId/:referralCode"
              exact
              component={ReferralValidationPage}
            />
            <Route path="/verify-account" exact component={VerifyAccount} />
            <Route path={`/${ROUTES.newOrder}`} component={NewOrderRoute} />
            <PrivateRoute
              path="/business/:encodedBusinessLevelId/home"
              exact
              component={SelfServeHome}
            />
            <PrivateRoute path="/" exact component={Landing} />
            {SELF_SERVE_ORDERING_ROUTES}
            <PrivateRoute
              path="/business/:encodedBusinessLevelId/welcome"
              exact
              component={Welcome}
            />
            <Route path="*" component={PageNotFound} />
          </Switch>
        </PullToRefresh>
      </CentsCustomerProvider>
    </Suspense>
  );
};
