import axios from "axios";
import {
  BASE_URL,
  BUSINESS_OWNER_AUTH_TOKEN_KEY,
  CUSTOMER_AUTH_TOKEN_KEY,
  CUSTOMER_KEY,
} from "utils";
import {handleLogout} from "utils/auth/handleLogout";
import {getParsedLocalStorageData} from "utils/common";
import {createErrorToast} from "utils/notifications/createErrorToast";
import {reissueToken} from "api/auth/reissueToken";
import {ACCESS_TOKEN_KEY} from "constants/auth";
import {NETWORK_ERROR} from "constants/errors/requestErrors";
import {
  NETWORK_ERROR_CODE,
  TIMEOUT_ERROR_CODE,
} from "constants/errors/requestErrorsCodes";
import {
  ORDER_IS_NOT_ASSOCIATED,
  TOKEN_IS_EXPIRED,
} from "constants/errors/validationErrors";
import {ORIGIN} from "constants/index";

export const getCustomerAuthToken = () => {
  try {
    return JSON.parse(localStorage.getItem(CUSTOMER_AUTH_TOKEN_KEY) || "null");
  } catch (error) {
    console.log(error);
    return null;
  }
};

const getManagerAuthToken = () => {
  const managerAuthToken = sessionStorage.getItem(BUSINESS_OWNER_AUTH_TOKEN_KEY);
  return managerAuthToken && managerAuthToken !== "null" ? managerAuthToken : null;
};

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  paramsSerializer: {
    serialize: (params) => {
      const filteredParams = new URLSearchParams();

      for (const [key, value] of Object.entries(params)) {
        if (value !== undefined) {
          const formattedValue =
            typeof value === "object" ? JSON.stringify(value) : String(value);

          filteredParams.append(key, formattedValue);
        }
      }

      return filteredParams.toString();
    },
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const managerAuthToken = getManagerAuthToken();

    config.headers.set("version", import.meta.env.VITE_VERSION);
    config.headers.set("fwdorigin", ORIGIN);

    const customer = getParsedLocalStorageData(CUSTOMER_KEY);

    if (customer?.id) {
      config.headers.set("customer-id", customer.id);
    }

    const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);

    if (accessToken) {
      // auth2 flow
      config.headers.set("Authorization", `Bearer ${accessToken}`);
    } else {
      // deprecated
      config.headers.set("customerAuthToken", getCustomerAuthToken());

      if (managerAuthToken) {
        config.headers.set("managerauthtoken", managerAuthToken);
      }
    }

    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    const res = error.response;

    if (
      !error.response &&
      [NETWORK_ERROR_CODE, TIMEOUT_ERROR_CODE].includes(error.code)
    ) {
      createErrorToast({
        primaryMessage: NETWORK_ERROR,
        toastId: "network-error",
      });
      return;
    }

    if (res?.status === 401) {
      if (res.data.error === TOKEN_IS_EXPIRED) {
        // if auth2 handles error about access token expiration happened
        // it reissues tokens pair and repeat request
        return reissueToken().then(
          (response) => {
            const {accessToken} = response.data;
            localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
            error.config.headers["Authorization"] = "Bearer " + accessToken;
            error.config.baseURL = undefined;

            return axiosInstance.request(error.config);
          },
          () => handleLogout() // logout user when refresh token is also expired
        );
      } else if (![ORDER_IS_NOT_ASSOCIATED].includes(res.data.error)) {
        // condition in order to continue show error without redirecting
        // when user tries open order page with incorrect phone number
        const token = res.config?.params?.token;
        setTimeout(() => {
          handleLogout({token});
        }, 1000);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
