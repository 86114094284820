import {FC, Ref, useEffect, useRef} from "react";
import {InputBase} from "@mui/material";
import type {InputBaseProps} from "@mui/material/InputBase";
import classNames from "classnames";
import InputMask, {
  BeforeMaskedStateChangeStates,
  InputState,
  Props,
} from "react-input-mask";
import collapseSpaceBetweenChars from "utils/collapseSpaceBetweenChars";
import {isIOSDevice} from "utils/common";
import styles from "./MaskedInput.module.scss";

type MaskedInputProps = Omit<InputBaseProps, "style"> & {
  sx?: Record<string, string[]>;
  maskProps?: any;
  isFocused?: boolean;
  customRef?: Ref<HTMLInputElement>;
  maskChar?: string;
  beforeMaskedStateChange?: (states: BeforeMaskedStateChangeStates) => InputState;
} & Omit<Props, "style" | "width">;

const MaskedInput: FC<MaskedInputProps> = ({
  onChange,
  beforeMaskedStateChange: beforeMaskedStateChangeAddition,
  mask,
  maskChar = "_",
  value = "",
  sx = {},
  maskProps = {},
  isFocused = true,
  customRef,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    isFocused ? inputRef?.current?.focus() : inputRef?.current?.blur();
  }, []);

  const beforeMaskedStateChange: (states: BeforeMaskedStateChangeStates) => InputState = (
    states: BeforeMaskedStateChangeStates
  ) => {
    const {
      nextState: {value, selection},
      previousState: {value: previousValue} = {},
    } = states;

    const newNextState = collapseSpaceBetweenChars(
      maskChar,
      mask as string,
      value,
      selection,
      previousValue
    );

    if (!beforeMaskedStateChangeAddition) {
      return newNextState;
    }

    return beforeMaskedStateChangeAddition({
      ...states,
      nextState: newNextState,
    });
  };

  return (
    <InputMask
      alwaysShowMask
      onChange={onChange}
      beforeMaskedStateChange={beforeMaskedStateChange}
      value={value}
      mask={mask}
      maskPlaceholder={maskChar}
      {...maskProps}
    >
      <InputBase
        className={classNames(styles.maskedInput, styles.main, {
          [styles.iosFontSize]: isIOSDevice(),
        })}
        sx={{...sx}}
        ref={customRef || inputRef}
        {...rest}
      />
    </InputMask>
  );
};

export default MaskedInput;
