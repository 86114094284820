import {captureException} from "@sentry/browser";
import {ENVIRONMENT} from "utils/config";
import {ExceptionErrors, formatRequestError} from "utils/formatters/formatRequestError";
import {createErrorToast} from "utils/notifications/createErrorToast";
import {EN_LOCALE} from "locales/en";
import {ApiError} from "types/api";

export const handleQueryError = (
  error: ApiError,
  {exceptionErrors}: {exceptionErrors?: ExceptionErrors} = {}
) => {
  if (Number(error?.response?.status) >= 500) {
    captureException(error);
  }

  const isProduction = ENVIRONMENT === "production";
  createErrorToast({
    message: formatRequestError({
      error,
      exceptionErrors,
      genericError: isProduction ? EN_LOCALE.messages.genericError : error.message,
    }),
  });
  console.error(error);

  return error;
};
